 /*==========================================================================
   Imports/Includes
   ========================================================================== */
@import '../variables';
@import '../mixins';


#page-index {
	.page-hero-banner {
		background-image:url(../../images/page-banners/home-welcome-1920x686.jpg);
	}
	.video-btn {
		height:40rem;
		-webkit-border-radius: 40px;
		-moz-border-radius: 40px;
		border-radius: 40px;
		background-image:url(../../images/video-bg.jpg);
		background-position:center;
		background-size:cover;
		background-repeat: no-repeat;
		width:60%;
		position:relative;
		overflow:hidden;
		margin-left:4rem;
		box-sizing: border-box;
		a {
			position:absolute;
			left:0;
			top:0;
			width:100%;
			height:100%;
			background-image:url(../../images/svg/videoplay-btn.php);
			background-position:center;
			background-size:60px 60px;
			background-repeat: no-repeat;
			transition:0.25s all;
			@include alpha-attribute('background-color',rgba($BLACK,0),$WHITE);
			&:hover {
				@include alpha-attribute('background-color',rgba($BLACK,0.4),$WHITE);
				transition:0.25s all;
			}
		}

	}
	.about-us {
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
		.copy-cont {
			width:40%;
		}
	}
	.quote {
		background-color: $THEMERED;
		h2,p {
			color: $WHITE;
			text-align:center;
		}
		h2 {
			font-family: $_FANCYFONT;
			span {
				font-family: $_STDFont;
				color: $WHITE;
				font-size:5rem;
			}
		}
		p {
			padding-bottom:0;
		}
	}

	@include breakWidth(800) {
		.page-hero-banner {
			background-image:none;
		}
		.about-us {
			.copy-cont {
				width:100%;
			}
		}
		.video-btn, .about-us {
			width:100%;
			margin:0 0 0 0;
			box-sizing: border-box;
		}
		.video-btn {
			height:300px;
			width:95%;
			margin-bottom:5rem;
			position:relative;
			left:2.5%;
			background-image:url(../../images/video-bg-650x427.jpg);
		}
		.flexRow {
			flex-direction:column;
			flex-wrap:wrap;
		}
	}
}